import React, { Component } from "react";
import { Link } from 'gatsby';
import { ArrowLink } from "../components/buttons/Arrow_Link.js";
import { ParallaxProvider } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';
import Navigation from "../components/Header/Navigation.js";
import PanoramicHero from "../components/Global/Panoramic_Hero.js";
import GDPR from '../components/Global/GDPR';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';

const data = [{
	acf: {
		slides: [{
			"title": "404",
			"copy": "Page Not Found",
			"cta": "Go Home",
			"link": "https://api.thecharlesnyc.com/",
			"vimeo_url": "https://player.vimeo.com/external/157479654.sd.mp4?s=eb747359ab9af86d39219739d8006a8d4cb9a560&profile_id=165"

		}]
	}
}];

class IndexPage extends Component {
	constructor(props){
		super(props);
		this.state = {
			views: 0
		}
	}
	componentDidMount() {
	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";


			return (
				<ParallaxProvider><PageTransition>
					<GDPR data={this.state.cookieBanner} />
					<div className="site-contain">
						<Navigation links={this.state.headerLinks} />
          				<div className="page-main">
							<div className={"page page-not-found page-ready page-loaded show-text"}>
							</div>
						</div>
					</div>
				</PageTransition></ParallaxProvider>
			);
	}
}

export default IndexPage
